var mySwiper = new Swiper(".swiper-container", {
	// Optional parameters
	direction: "horizontal",
	loop: true,
	// centeredSlides: true,

	// If we need pagination
	// pagination: {
	// 	el: ".swiper-pagination",
	// },

	// Navigation arrows
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},

	// And if we need scrollbar
	// scrollbar: {
	// 	el: ".swiper-scrollbar",
	// },
	slidesPerView: 4,
	// slidesPerGroup: 4,
	// slidesPerView: "auto",
	spaceBetween: 30,
	autoplay: {
		delay: 2500,
		disableOnInteraction: false,
	},
	breakpoints: {
		// when window width is >= 320px
		320: {
			slidesPerView: 1,
			spaceBetween: 0,
		},
		// when window width is >= 480px
		480: {
			slidesPerView: 1,
			spaceBetween: 0,
		},
		// when window width is >= 640px
		640: {
			slidesPerView: 2,
			spaceBetween: 0,
		},
		1200: {
			slidesPerView: 3,
			spaceBetween: 0,
		},
	},
	// loopFillGroupWithBlank: true,
});
